import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { mainBlue, screen } from "../components/common/variables";
import { toSlug } from "../components/helpers";
import { StoreContext } from "../context";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/page-title";
import Tabs from "../components/products/tabs/lance-tab";
import ProductSidebar from "../components/products/sidebar";

const Wrapper = styled.div`
  padding-bottom: 110px;
  padding-top: 73px;
  @media ${screen.xsmall} {
    padding-top: 87px;
    padding-bottom: 170px;
  }
  @media ${screen.small} {
    padding-top: 80px;
  }
  @media ${screen.xlarge} {
    padding-bottom: 200px;
  }

  .foundry-introduction {
    color: ${mainBlue};
    display: flex;
    position: relative;
    z-index: 2;
    justify-content: space-between;

    &__text {
      max-width: 600px;
      width: 100%;
      padding-left: 35px;
      padding-right: 35px;
      padding-top: 35px;
      @media ${screen.xsmall} {
        margin-top: 140px;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
      }
      @media ${screen.small} {
        margin-top: 0;
      }

      .heading {
        /* max-width: 420px; */
        line-height: 1.25;
      }

      .subheading {
        font-size: 1.17rem;
        font-weight: 300;
        margin: 20px 0;
        @media ${screen.xsmall} {
          font-size: 1.4rem;
        }

        @media ${screen.xlarge} {
          font-size: 1.5rem;
          margin: 25px 0;
        }
      }

      .description {
        margin-top: 18px;
        max-width: 503px;
        p {
          font-size: 1.08rem;
          margin-bottom: 18px;
          @media ${screen.xsmall} {
            font-size: 1.08rem;
            margin-bottom: 22px;
          }
          @media ${screen.xlarge} {
            font-size: 1.25rem;
            margin-bottom: 30px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &__img {
      width: 100%;
      max-width: 600px;
      margin-left: 95px;
      display: none;
      @media ${screen.xsmall} {
        display: block;
        margin-left: 40px;
        margin-top: 40px;
      }
      @media ${screen.small} {
        margin-left: 95px;
        margin-top: 0;
      }

      .featured {
      }
    }
  }

  .inner-wrapper {
    @media ${screen.xsmall} {
      padding-left: 70px;
      padding-right: 70px;
    }
    @media ${screen.small} {
      padding-top: 82px;
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-top: 100px;
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.xlarge} {
      padding-top: 140px;
      padding-left: 280px;
      padding-right: 280px;
    }
  }

  .accessories {
    margin-bottom: 60px;
    padding-top: 35px;
    padding-left: 35px;
    padding-right: 35px;
    @media ${screen.xsmall} {
      padding-top: 50px;
      padding-left: 0;
      padding-right: 0;
    }
    @media ${screen.small} {
      padding-top: 0;
    }
    @media ${screen.xlarge} {
      margin-bottom: 80px;
    }

    &__heading {
      margin-bottom: 9px;
      line-height: 1.25;
      @media ${screen.xsmall} {
        margin-bottom: 35px;
      }
      @media ${screen.xlarge} {
        margin-bottom: 45px;
      }
    }
  }
`;

const ThermicProductsPage = ({ data }) => {
  const {
    addOrder,
    selectedProduct,
    setProductSidebar,
    isProductSidebarOpen,
    setProduct,
    addUrlSearch,
    removeUrlSearch,
    urlSearch,
    orders,
  } = useContext(StoreContext);

  const urlPath =
    typeof window !== `undefined` && window.location.pathname.substring(1);

  const {
    main_heading,
    all_products_description,
    calorised_lance_pipes_description,
    handles_description,
    kits_description,
    lit_process_description,
    safety_description,
    spare_parts_description,
    title_tag,
    meta_description,
    product_list,
  } = data.main.data;

  const allProducts = product_list.map((item) => {
    return {
      node: {
        type: item.product.type,
        id: item.product.document.id,
        data: item.product.document.data,
      },
    };
  });

  const lances = allProducts.filter(
    (item) => item.node.type === "thermic_lance"
  );
  const parts = allProducts.filter(
    (item) => item.node.type === "spare_part_thermic_lance_accessory"
  );
  const kits = allProducts.filter(
    (item) => item.node.type === "kit_thermic_lance_accessory"
  );
  const safety = allProducts.filter(
    (item) => item.node.type === "safety_thermic_lance_accessory"
  );
  const handles = allProducts.filter(
    (item) => item.node.type === "handle_thermic_lance_accessory"
  );
  const pipes = allProducts.filter(
    (item) => item.node.type === "calorised_lance_pipe_thermic_lance_accessory"
  );
  const lit = allProducts.filter(
    (item) => item.node.type === "lit_process_thermic_lance_accessory"
  );

  const products = allProducts;

  useEffect(() => {
    products.forEach((product) => {
      if (toSlug(product.node.data.name.text) === urlSearch) {
        setProduct({
          id: product.node.id,
          name: product.node.data.name.text,
          description:
            product.node.data.description && product.node.data.description.html,
          images: product.node.data.images.length && product.node.data.images,
          column1Heading:
            product.node.data.column_1_heading &&
            product.node.data.column_1_heading.text,
          column2Heading:
            product.node.data.column_2_heading &&
            product.node.data.column_2_heading.text,
          column3Heading:
            product.node.data.column_3_heading &&
            product.node.data.column_3_heading.text,
          column4Heading:
            product.node.data.column_4_heading &&
            product.node.data.column_4_heading.text,
          column5Heading:
            product.node.data.column_5_heading &&
            product.node.data.column_5_heading.text,
          column6Heading:
            product.node.data.column_6_heading &&
            product.node.data.column_6_heading.text,
          column1Subheading: product.node.data.column_1_subheading,
          column2Subheading: product.node.data.column_2_subheading,
          column3Subheading: product.node.data.column_3_subheading,
          column4Subheading: product.node.data.column_4_subheading,
          column5Subheading: product.node.data.column_5_subheading,
          column6Subheading: product.node.data.column_6_subheading,
          tableRows: product.node.data.rows,
          relatedProducts: product.node.data.product_list,
        });
        setProductSidebar(true);
        return;
      }
    });
  }, [urlSearch]);

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <div
          className="inner-wrapper inner-wrapper--products"
          id="thermic-products"
        >
          <section className="accessories">
            <PageTitle className="accessories__heading">
              {main_heading}
            </PageTitle>
            <Tabs
              thermic={{
                product_list: allProducts,
                lances,
                parts,
                kits,
                safety,
                handles,
                pipes,
                lit,
                all_products_description,
                calorised_lance_pipes_description,
                handles_description,
                kits_description,
                lit_process_description,
                safety_description,
                spare_parts_description,
              }}
              addUrlSearch={addUrlSearch}
              urlSearch={urlSearch}
              setProductSidebar={setProductSidebar}
              setProduct={setProduct}
              addOrder={addOrder}
              urlPath={urlPath}
            />

            <ProductSidebar
              isProductSidebarOpen={isProductSidebarOpen}
              setProduct={setProduct}
              selectedProduct={selectedProduct}
              setProductSidebar={setProductSidebar}
              removeUrlSearch={removeUrlSearch}
              addOrder={addOrder}
              orders={orders}
              productType={"lance"}
              urlPath={urlPath}
              addUrlSearch={addUrlSearch}
              products={products}
            />
          </section>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default ThermicProductsPage;

export const dataQuery = graphql`
  {
    main: prismicThermicProductsPage {
      data {
        main_heading
        main_subheading
        title_tag
        meta_description
        main_description {
          html
        }
        lances_description {
          html
        }
        all_products_description {
          html
        }
        calorised_lance_pipes_description {
          html
        }
        handles_description {
          html
        }
        kits_description {
          html
        }
        lit_process_description {
          html
        }
        safety_description {
          html
        }
        spare_parts_description {
          html
        }
        product_list {
          product {
            type
            document {
              ... on PrismicCalorisedLancePipeThermicLanceAccessory {
                id
                data {
                  name {
                    text
                  }
                  description {
                    html
                  }
                  images {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  column_1_heading {
                    text
                  }
                  column_2_heading {
                    text
                  }
                  column_3_heading {
                    text
                  }
                  column_4_heading {
                    text
                  }
                  column_5_heading {
                    text
                  }
                  column_6_heading {
                    text
                  }
                  column_1_subheading
                  column_2_subheading
                  column_3_subheading
                  column_4_subheading
                  column_5_subheading
                  column_6_subheading
                  rows {
                    column_1_data
                    column_2_data
                    column_3_data
                    column_4_data
                    column_5_data
                    column_6_data
                  }
                  product_list {
                    product {
                      document {
                        ... on PrismicCalorisedLancePipeThermicLanceAccessory {
                          id
                        }
                        ... on PrismicHandleThermicLanceAccessory {
                          id
                        }
                        ... on PrismicLitProcessThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSafetyThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSparePartThermicLanceAccessory {
                          id
                        }
                        ... on PrismicThermicLance {
                          id
                        }
                        ... on PrismicKitThermicLanceAccessory {
                          id
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicHandleThermicLanceAccessory {
                id
                data {
                  name {
                    text
                  }
                  description {
                    html
                  }
                  images {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  column_1_heading {
                    text
                  }
                  column_2_heading {
                    text
                  }
                  column_3_heading {
                    text
                  }
                  column_4_heading {
                    text
                  }
                  column_5_heading {
                    text
                  }
                  column_6_heading {
                    text
                  }
                  column_1_subheading
                  column_2_subheading
                  column_3_subheading
                  column_4_subheading
                  column_5_subheading
                  column_6_subheading
                  rows {
                    column_1_data
                    column_2_data
                    column_3_data
                    column_4_data
                    column_5_data
                    column_6_data
                  }
                  product_list {
                    product {
                      document {
                        ... on PrismicCalorisedLancePipeThermicLanceAccessory {
                          id
                        }
                        ... on PrismicHandleThermicLanceAccessory {
                          id
                        }
                        ... on PrismicLitProcessThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSafetyThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSparePartThermicLanceAccessory {
                          id
                        }
                        ... on PrismicThermicLance {
                          id
                        }
                        ... on PrismicKitThermicLanceAccessory {
                          id
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicLitProcessThermicLanceAccessory {
                id
                data {
                  name {
                    text
                  }
                  description {
                    html
                  }
                  images {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  column_1_heading {
                    text
                  }
                  column_2_heading {
                    text
                  }
                  column_3_heading {
                    text
                  }
                  column_4_heading {
                    text
                  }
                  column_5_heading {
                    text
                  }
                  column_6_heading {
                    text
                  }
                  column_1_subheading
                  column_2_subheading
                  column_3_subheading
                  column_4_subheading
                  column_5_subheading
                  column_6_subheading
                  rows {
                    column_1_data
                    column_2_data
                    column_3_data
                    column_4_data
                    column_5_data
                    column_6_data
                  }
                  product_list {
                    product {
                      document {
                        ... on PrismicCalorisedLancePipeThermicLanceAccessory {
                          id
                        }
                        ... on PrismicHandleThermicLanceAccessory {
                          id
                        }
                        ... on PrismicLitProcessThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSafetyThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSparePartThermicLanceAccessory {
                          id
                        }
                        ... on PrismicThermicLance {
                          id
                        }
                        ... on PrismicKitThermicLanceAccessory {
                          id
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicSafetyThermicLanceAccessory {
                id
                data {
                  name {
                    text
                  }
                  description {
                    html
                  }
                  images {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  column_1_heading {
                    text
                  }
                  column_2_heading {
                    text
                  }
                  column_3_heading {
                    text
                  }
                  column_4_heading {
                    text
                  }
                  column_5_heading {
                    text
                  }
                  column_6_heading {
                    text
                  }
                  column_1_subheading
                  column_2_subheading
                  column_3_subheading
                  column_4_subheading
                  column_5_subheading
                  column_6_subheading
                  rows {
                    column_1_data
                    column_2_data
                    column_3_data
                    column_4_data
                    column_5_data
                    column_6_data
                  }
                  product_list {
                    product {
                      document {
                        ... on PrismicCalorisedLancePipeThermicLanceAccessory {
                          id
                        }
                        ... on PrismicHandleThermicLanceAccessory {
                          id
                        }
                        ... on PrismicLitProcessThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSafetyThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSparePartThermicLanceAccessory {
                          id
                        }
                        ... on PrismicThermicLance {
                          id
                        }
                        ... on PrismicKitThermicLanceAccessory {
                          id
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicSparePartThermicLanceAccessory {
                id
                data {
                  name {
                    text
                  }
                  description {
                    html
                  }
                  images {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  column_1_heading {
                    text
                  }
                  column_2_heading {
                    text
                  }
                  column_3_heading {
                    text
                  }
                  column_4_heading {
                    text
                  }
                  column_5_heading {
                    text
                  }
                  column_6_heading {
                    text
                  }
                  column_1_subheading
                  column_2_subheading
                  column_3_subheading
                  column_4_subheading
                  column_5_subheading
                  column_6_subheading
                  rows {
                    column_1_data
                    column_2_data
                    column_3_data
                    column_4_data
                    column_5_data
                    column_6_data
                  }
                  product_list {
                    product {
                      document {
                        ... on PrismicCalorisedLancePipeThermicLanceAccessory {
                          id
                        }
                        ... on PrismicHandleThermicLanceAccessory {
                          id
                        }
                        ... on PrismicLitProcessThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSafetyThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSparePartThermicLanceAccessory {
                          id
                        }
                        ... on PrismicThermicLance {
                          id
                        }
                        ... on PrismicKitThermicLanceAccessory {
                          id
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicThermicLance {
                id
                data {
                  name {
                    text
                  }
                  description {
                    html
                  }
                  images {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  column_1_heading {
                    text
                  }
                  column_2_heading {
                    text
                  }
                  column_3_heading {
                    text
                  }
                  column_4_heading {
                    text
                  }
                  column_5_heading {
                    text
                  }
                  column_6_heading {
                    text
                  }
                  column_1_subheading
                  column_2_subheading
                  column_3_subheading
                  column_4_subheading
                  column_5_subheading
                  column_6_subheading
                  rows {
                    column_1_data
                    column_2_data
                    column_3_data
                    column_4_data
                    column_5_data
                    column_6_data
                  }
                  product_list {
                    product {
                      document {
                        ... on PrismicCalorisedLancePipeThermicLanceAccessory {
                          id
                        }
                        ... on PrismicHandleThermicLanceAccessory {
                          id
                        }
                        ... on PrismicLitProcessThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSafetyThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSparePartThermicLanceAccessory {
                          id
                        }
                        ... on PrismicThermicLance {
                          id
                        }
                        ... on PrismicKitThermicLanceAccessory {
                          id
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicKitThermicLanceAccessory {
                id
                data {
                  name {
                    text
                  }
                  description {
                    html
                  }
                  images {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  column_1_heading {
                    text
                  }
                  column_2_heading {
                    text
                  }
                  column_3_heading {
                    text
                  }
                  column_4_heading {
                    text
                  }
                  column_5_heading {
                    text
                  }
                  column_6_heading {
                    text
                  }
                  column_1_subheading
                  column_2_subheading
                  column_3_subheading
                  column_4_subheading
                  column_5_subheading
                  column_6_subheading
                  rows {
                    column_1_data
                    column_2_data
                    column_3_data
                    column_4_data
                    column_5_data
                    column_6_data
                  }
                  product_list {
                    product {
                      document {
                        ... on PrismicCalorisedLancePipeThermicLanceAccessory {
                          id
                        }
                        ... on PrismicHandleThermicLanceAccessory {
                          id
                        }
                        ... on PrismicLitProcessThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSafetyThermicLanceAccessory {
                          id
                        }
                        ... on PrismicSparePartThermicLanceAccessory {
                          id
                        }
                        ... on PrismicThermicLance {
                          id
                        }
                        ... on PrismicKitThermicLanceAccessory {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
